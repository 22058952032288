var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import momentTZ from 'moment-timezone';
import Dropzone from 'react-dropzone';
import { MERCHI_SDK } from '../public_components/merchi-sdk';
import { frontendUri, httpProtocol } from '../public_components/url-utility';
import { externalUrlFor, urlFor, publicUrlFor } from '../public_components/url_map';
import { icons } from './icons';
import { formatCurrency } from './currency';
import ReactTooltip from 'react-tooltip-old';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, } from 'reactstrap';
import { Merchi } from 'merchi_sdk_ts';
export function appendStyleSheet(href, callback) {
    var newLink = document.createElement('link');
    newLink.href = href;
    newLink.rel = 'stylesheet';
    if (callback) {
        newLink.onload = callback;
    }
    document.head.appendChild(newLink);
}
export function appendStyleSheetText(css, callback) {
    var newStyleSheet = document.createElement('style');
    newStyleSheet.textContent = css;
    if (callback) {
        newStyleSheet.onload = callback;
    }
    document.head.appendChild(newStyleSheet);
}
export var fetchFileFromStatic = function (fileName) {
    return urlFor('static', { filename: fileName });
};
export var targetIsSafe = function (target) {
    return target.indexOf(httpProtocol + frontendUri) === 0;
};
export var defaultAlertObj = {
    alertType: 'info',
    message: '',
    show: false,
    title: '',
    type: 'info',
};
export var successAlertObj = function (msg) {
    return {
        alertType: 'success',
        message: msg,
        show: true,
        title: 'Success',
        type: 'success',
    };
};
export var errorAlertObject = function (msg) {
    return {
        alertType: 'danger',
        message: msg,
        show: true,
        title: 'Error',
        type: 'danger',
    };
};
export var timezones = new MERCHI_SDK.Dictionary();
momentTZ.tz.names().map(function (tz) {
    timezones.add(tz, tz);
});
export var supportedCountries = new MERCHI_SDK.Dictionary();
supportedCountries.add('AU', 'Australia');
supportedCountries.add('AE', 'United Arab Emirates');
supportedCountries.add('BH', 'Bahrain');
supportedCountries.add('CN', 'China');
supportedCountries.add('DK', 'Denmark');
supportedCountries.add('ES', 'Spain');
supportedCountries.add('FR', 'France');
supportedCountries.add('GB', 'Great Britan');
supportedCountries.add('HK', 'Hong Kong');
supportedCountries.add('ID', 'Indonesia');
supportedCountries.add('IN', 'India');
supportedCountries.add('IT', 'Italy');
supportedCountries.add('KR', 'South Korea');
supportedCountries.add('NC', 'New Caledonia');
supportedCountries.add('NZ', 'New Zealand');
supportedCountries.add('OM', 'Oman');
supportedCountries.add('PH', 'Philippines');
supportedCountries.add('PT', 'Portugal');
supportedCountries.add('QA', 'Qatar');
supportedCountries.add('SE', 'Sweden');
supportedCountries.add('SG', 'Singapore');
supportedCountries.add('TH', 'Thailand');
supportedCountries.add('US', 'United States');
supportedCountries.add('ZA', 'South Africa');
export var hideCardBodyIfNotActive = function (isActive) {
    return 'card-body ' + (!isActive ? 'hide' : '');
};
export var countryDefaultDict = function (currency) {
    return { currency: currency };
};
export var supportedCountryDefaults = {
    AE: countryDefaultDict('USD'),
    AU: countryDefaultDict('AUD'),
    BH: countryDefaultDict('USD'),
    CH: countryDefaultDict('CNY'),
    DK: countryDefaultDict('EUR'),
    ES: countryDefaultDict('EUR'),
    FR: countryDefaultDict('EUR'),
    GB: countryDefaultDict('GBP'),
    HK: countryDefaultDict('HKD'),
    ID: countryDefaultDict('USD'),
    IN: countryDefaultDict('INR'),
    IT: countryDefaultDict('EUR'),
    KR: countryDefaultDict('USD'),
    NC: countryDefaultDict('USD'),
    NZ: countryDefaultDict('NZD'),
    OM: countryDefaultDict('USD'),
    PH: countryDefaultDict('USD'),
    PT: countryDefaultDict('EUR'),
    QA: countryDefaultDict('USD'),
    SE: countryDefaultDict('USD'),
    SG: countryDefaultDict('SGD'),
    TH: countryDefaultDict('USD'),
    US: countryDefaultDict('USD'),
    ZA: countryDefaultDict('USD'),
};
var isCountrySupported = function (countryCode) {
    return Boolean(supportedCountryDefaults[countryCode]);
};
export var supportedCountryDefaultsWithDefault = function (countryCode) {
    var defaultCountryValues = supportedCountryDefaults[countryCode];
    return defaultCountryValues ? defaultCountryValues : supportedCountryDefaults.AU;
};
export var getSupportedCountryCodeFromIp = function (options) {
    var callback = options.callback, _a = options.defualtCountryCode, defualtCountryCode = _a === void 0 ? 'AU' : _a, _b = options.timeout, timeout = _b === void 0 ? 3000 : _b;
    var didTimeOut = false;
    var _timeout = setTimeout(function () {
        didTimeOut = true;
        console.error('Unable to fetch country code - Request timed out.');
    }, timeout);
    var request = new MERCHI_SDK.Request();
    request.resource('/geoip/');
    request.responseHandler(function (status, data) {
        clearTimeout(_timeout);
        if (!didTimeOut) {
            var json = JSON.parse(data);
            var _a = json.countryCode, countryCode = _a === void 0 ? defualtCountryCode : _a;
            callback(isCountrySupported(countryCode) ? countryCode : defualtCountryCode);
        }
    });
    request.send();
};
export var userProfileUrl = function (id) {
    return urlFor('user_edit', { identifier: id });
};
export var publicProductProfileUrl = function (product) {
    var domain = product.domain();
    return publicUrlFor('domain_product_public', { id: domain.id(),
        product_id: product.id(),
        subdomain: domain.subDomain() });
};
export function setGlobalNotificationCount(count) {
    var elements = document.getElementsByClassName('global-notification-count');
    var i;
    if (elements) {
        for (i in elements) {
            if (typeof elements[i] === 'object') {
                elements[i].innerHTML = count ? count : '';
            }
        }
    }
}
export function timeUntilDeadline(unixTime) {
    var time = timeSegmentation(unixTime);
    var days = time.days;
    var hours = time.hours;
    var minutes = time.minutes;
    var seconds = time.seconds;
    var isNeg = seconds < 0;
    var stringEnd = isNeg ? '' : ' past';
    var timeString = '';
    var textClass = !isNeg ? 'text-danger' : '';
    var absDays = Math.abs(days);
    function pluralise(value, text) {
        return String(value) + ' ' + text + (value > 1 ? 's ' : '');
    }
    if (days !== 0) {
        if (absDays <= 1) {
            textClass = 'text-danger';
        }
        else if (isNeg && absDays <= 2) {
            textClass = 'text-warning';
        }
        timeString = pluralise(Math.abs(days), 'day');
    }
    else if (hours !== 0) {
        timeString = pluralise(Math.abs(hours), 'hour');
    }
    else if (minutes !== 0) {
        timeString = pluralise(Math.abs(minutes), 'minute');
    }
    else if (seconds !== 0) {
        timeString = pluralise(Math.abs(seconds), 'second');
    }
    return { text: timeString + stringEnd,
        textClass: textClass };
}
export var timeSegmentation = function (unixTime) {
    var timeNow = Math.floor(Date.now() / 1000);
    var timeSince = timeNow - unixTime;
    var seconds = Math.floor(timeSince);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    return { days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds };
};
export var timeSinceReceived = function (unixTime) {
    var time = timeSegmentation(unixTime);
    function timeString(timeSegment, timeValue) {
        var absTime = Math.abs(timeValue);
        return String(absTime) + ' ' + timeSegment +
            (absTime > 1 ? 's ago' : ' ago');
    }
    if (time.days !== 0) {
        return timeString('day', time.days);
    }
    else if (time.hours !== 0) {
        return timeString('hour', time.hours);
    }
    else if (time.minutes !== 0) {
        return timeString('minute', time.minutes);
    }
    else if (time.seconds !== 0) {
        return timeString('second', time.seconds);
    }
    return '';
};
export var guidGenerator = function () {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' +
        S4() + '-' + S4() + S4() + S4());
};
export var handelRequestErrorMsg = function (defaultMsg, data) {
    return data && data.message ? data.message : defaultMsg;
};
export var handleServerErrorMsg = function (data) {
    return data && data.message ? data.message :
        'Unknown server error';
};
export var merchiFullColourLogo = externalUrlFor('static', { filename: 'images/merchi-master-colour.png' });
export var merchiBrandLogoWhite = externalUrlFor('static', { filename: 'svg/white-merchi.svg' });
export var merchiLoadingUrl = externalUrlFor('static', { filename: 'images/merchi-monster-loader.gif' });
export var merchiMascotUrl = externalUrlFor('static', { filename: 'images/merchi-monster-blue.png' });
export var productNotFoundUrl = externalUrlFor('static', { filename: 'images/product-not-found.png' });
export var themeNotFoundUrl = externalUrlFor('static', { filename: 'images/product-not-found.png' });
export var userNotFoundUrl = externalUrlFor('static', { filename: 'images/default-user-32px.jpg' });
export var invitationSentAvatarUrl = externalUrlFor('static', { filename: 'images/merchi-monster-blue.png' });
export var defaultCompanyLogo = externalUrlFor('static', { filename: 'images/merchi-monster-blue.png' });
export var defaultProductFeature = externalUrlFor('static', { filename: 'images/merchi-monster-blue.png' });
export var Alert = function (props) {
    var alertObj = props.alertObj;
    var className = props.className ? props.className : '';
    var title = {
        danger: 'Error!',
        error: 'Error!',
        info: 'Info!',
        success: 'Success!',
        warning: 'Warning!',
    };
    var alertClass = {
        danger: ' alert-danger ',
        error: ' alert-danger ',
        info: ' alert-info ',
        success: ' alert-success ',
        warning: ' alert-warning ',
    };
    return (React.createElement("div", { className: 'alert' + alertClass[alertObj.alertType] +
            ' ' + className + ' ' +
            (alertObj.show ? '' : ' hide') },
        React.createElement("strong", { className: 'm-r-5' }, title[alertObj.alertType]),
        alertObj.message,
        React.createElement("span", { className: 'close', onClick: function () {
                props.showAlert({
                    alertType: 'info',
                    message: '',
                    show: false,
                });
            } }, "\u00D7")));
};
export var TooltipElement = function (props) {
    return (React.createElement(ReactTooltip, { id: props.id, effect: 'float', type: 'dark' },
        React.createElement("span", null, props.text)));
};
export function tooltipSpan(textHtml, toolTip) {
    var ttid = MERCHI_SDK.generateUUID();
    return (React.createElement("span", null,
        React.createElement(ReactTooltip, { id: ttid, effect: 'float', type: 'dark' },
            React.createElement("span", null, toolTip)),
        React.createElement("span", { "data-tip": true, "data-for": ttid, style: { display: 'inline-block' } }, textHtml)));
}
export function iconWithToolTip(iconClass, tooltip) {
    return tooltipSpan(iconElement(iconClass), tooltip);
}
export function htmlIcon(iconClass) {
    return React.createElement("i", { className: iconClass + ' m-r-3' });
}
export var iconElement = function (iconClass) {
    return React.createElement("i", { className: iconClass });
};
var CountdownTimer = /** @class */ (function (_super) {
    __extends(CountdownTimer, _super);
    function CountdownTimer() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            timeEnd: _this.props.timeEnd,
            timeUp: false,
        };
        return _this;
    }
    CountdownTimer.prototype.componentDidMount = function () {
        this.timeCount = setInterval(this.count.bind(this), 1000);
    };
    CountdownTimer.prototype.count = function () {
        var timeEnd = this.state.timeEnd;
        var _a = this.props, options = _a.options, callback = _a.callback;
        if (options !== undefined) {
            var atTimeCallback = options.atTimeCallback;
            if (atTimeCallback !== undefined && timeEnd !== undefined
                && atTimeCallback.time !== undefined &&
                atTimeCallback.callback !== undefined) {
                if (atTimeCallback.time === timeEnd * 1000) {
                    atTimeCallback.callback();
                }
            }
        }
        var d;
        var h;
        var m;
        var s;
        var rest;
        if (timeEnd >= 0) {
            rest = timeEnd;
            s = Math.floor(rest % 60);
            rest = rest / 60;
            m = Math.floor(rest % 60);
            rest = rest / 60;
            h = Math.floor(rest % 24);
            rest = rest / 24;
            d = Math.floor(rest);
            var newTimeEnd = timeEnd - 1;
            this.setState({
                d: d,
                h: h,
                m: m,
                s: s,
                timeEnd: newTimeEnd,
            });
        }
        else {
            clearInterval(this.timeCount);
            if (typeof callback === 'function') {
                callback();
            }
            this.setState({ timeUp: true });
        }
    };
    CountdownTimer.prototype.componentWillUnmount = function () {
        clearInterval(this.timeCount);
    };
    CountdownTimer.prototype.render = function () {
        var _a = this.state, d = _a.d, h = _a.h, m = _a.m, s = _a.s, timeUp = _a.timeUp;
        var _b = this.props, className = _b.className, _c = _b.hideSeconds, hideSeconds = _c === void 0 ? false : _c, style = _b.style, model = _b.model;
        var qs = s ? s + 's' : '';
        var qm = m ? m + 'm ' : '';
        var qh = h ? h + 'h ' : '';
        var qd = d ? d + 'd ' : '';
        var result;
        if (d !== undefined) {
            switch (model) {
                case 'standard':
                    result = "".concat(qh, " : ").concat(qm, " : ").concat(qs);
                    break;
                case 'text':
                    result = "".concat(qd).concat(qh).concat(qm).concat(!hideSeconds ? qs : '');
                    break;
            }
        }
        return (React.createElement("span", { className: className, style: style }, timeUp ? 'time is up' : result));
    };
    CountdownTimer.defaultProps = {
        model: 'text',
    };
    return CountdownTimer;
}(React.Component));
export { CountdownTimer };
export function alertTemplate(props) {
    var alertType = props.alertType;
    var title = {
        danger: 'Error!',
        error: 'Error!',
        info: 'Info!',
        success: 'Success!',
        warning: 'Warning!',
    };
    var alertClass = {
        danger: ' alert-danger ',
        error: ' alert-danger ',
        info: ' alert-info ',
        success: ' alert-success ',
        warning: ' alert-warning ',
    };
    return React.createElement("div", { className: 'alert' + alertClass[alertType] + 'fade in m-b-15' },
        React.createElement("strong", { className: 'm-r-5' }, title[alertType]),
        " ",
        props.message,
        React.createElement("span", { className: 'close', onClick: props.hideAlert }, "\u00D7"));
}
var Switch = /** @class */ (function (_super) {
    __extends(Switch, _super);
    function Switch(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChange = function () {
            _this.setState({ isChecked: !_this.state.isChecked });
            _this.props.onChange();
        };
        _this.state = {
            isChecked: props.isChecked,
        };
        return _this;
    }
    Switch.prototype.render = function () {
        var style = this.props.style ? this.props.style : { float: 'left' };
        return (React.createElement("span", { className: this.props.className, style: style },
            React.createElement("input", { checked: this.state.isChecked, onChange: this.handleChange, id: this.props.id, className: 'switch-small', type: 'checkbox' }),
            React.createElement("div", null,
                React.createElement("div", null))));
    };
    return Switch;
}(React.Component));
export { Switch };
export function formatDate(user, unixDate) {
    return unixDate ?
        user.userLocalTime(unixDate * 1000) : null;
}
export function customFormatDate(user, unixDate, format) {
    return unixDate ?
        user.userLocalTimeFormat(unixDate * 1000, format) : null;
}
var SavedAddressesList = /** @class */ (function (_super) {
    __extends(SavedAddressesList, _super);
    function SavedAddressesList(props) {
        var _this = _super.call(this, props) || this;
        _this.gotAddresses = function (addresses) {
            _this.setState({
                addresses: addresses,
                loading: false
            });
        };
        _this.errorAddresses = function () {
            _this.setState({
                addresses: [],
                loading: false
            });
        };
        _this.fetchRelatedAddresses = function () {
            var self = _this;
            var props = self.props;
            _this.setState({ loading: true }, function () {
                MERCHI_SDK.addresses.getRelated({
                    companyId: props.companyId,
                    error: self.errorAddresses,
                    jobId: props.jobId,
                    success: self.gotAddresses,
                    userId: props.userId,
                });
            });
        };
        _this.openDropdown = function (open) {
            _this.setState({ open: open }, function () {
                if (open) {
                    _this.fetchRelatedAddresses();
                }
            });
        };
        _this.state = {
            addresses: props.addresses ? props.addresses : [],
            loading: false,
            open: false,
        };
        return _this;
    }
    SavedAddressesList.prototype.render = function () {
        var _this = this;
        var loading = this.state.loading;
        var className = this.props.className ?
            this.props.className : '';
        var open = this.state.open ? ' open' : '';
        var addresses = this.state.addresses;
        var listItems = [];
        addresses.map(function (address) {
            listItems.push(React.createElement("li", { key: address.temporaryId },
                React.createElement("a", { onClick: function () {
                        _this.props.applyAddress(address);
                        _this.openDropdown(false);
                    } }, address.fullAddressString())));
        });
        return (React.createElement("div", { className: className + open },
            React.createElement("button", { type: 'button', className: 'btn btn-link btn-xs dropdown-toggle', onClick: function () {
                    _this.openDropdown(!_this.state.open);
                } },
                "Saved ",
                React.createElement("span", { className: 'hidden-md hidden-sm' }, "Addresses"),
                " ",
                React.createElement("span", { className: 'caret' })),
            React.createElement("ul", { className: 'dropdown-menu', style: { textAlign: 'left', top: 'inherit' } }, loading ?
                (React.createElement("li", { className: 'text-center', key: MERCHI_SDK.generateUUID() },
                    htmlIcon(icons.loading),
                    " Loading...")) :
                addresses.length > 0 ? listItems :
                    (React.createElement("li", { className: 'text-center', key: MERCHI_SDK.generateUUID() }, "No addresses found")))));
    };
    return SavedAddressesList;
}(React.Component));
export { SavedAddressesList };
var AddressesNotFoundItem = function () {
    return React.createElement(DropdownItem, { header: true, key: MERCHI_SDK.generateUUID() }, "No addresses found");
};
var AddressesLoadingItem = function () {
    return React.createElement(DropdownItem, { header: true },
        iconElement(icons.loading),
        " Loading...");
};
var AddressesAsItems = function (addresses, applyAddress) {
    return addresses.length > 0 ?
        addresses.map(function (address) {
            return (React.createElement(DropdownItem, { key: address.temporaryId, style: { cursor: 'pointer' }, onClick: function () { return applyAddress(address); } }, address.fullAddressString()));
        }) : React.createElement(AddressesNotFoundItem, null);
};
var BootstrapSavedAddressesList = /** @class */ (function (_super) {
    __extends(BootstrapSavedAddressesList, _super);
    function BootstrapSavedAddressesList(props) {
        var _this = _super.call(this, props) || this;
        _this.gotAddresses = function (addresses) {
            _this.setState({
                addresses: addresses,
                loading: false
            });
        };
        _this.errorAddresses = function () {
            _this.setState({
                addresses: [],
                loading: false
            });
        };
        _this.fetchRelatedAddresses = function () {
            var self = _this;
            var props = self.props;
            _this.setState({ loading: true }, function () {
                MERCHI_SDK.addresses.getRelated({
                    companyId: props.companyId,
                    error: self.errorAddresses,
                    jobId: props.jobId,
                    success: self.gotAddresses,
                    userId: props.userId,
                });
            });
        };
        _this.toggle = function () {
            var isOpen = !_this.state.isOpen;
            if (isOpen) {
                _this.fetchRelatedAddresses();
            }
            _this.setState({ isOpen: isOpen });
        };
        _this.state = {
            addresses: [],
            isOpen: false,
            loading: false,
        };
        return _this;
    }
    BootstrapSavedAddressesList.prototype.render = function () {
        var _this = this;
        var _a = this.state, addresses = _a.addresses, isOpen = _a.isOpen, loading = _a.loading;
        var className = this.props.className;
        return (React.createElement("div", { className: className, style: { display: 'inline' } },
            React.createElement(Dropdown, { isOpen: isOpen, toggle: this.toggle, size: 'sm' },
                React.createElement(DropdownToggle, { caret: true, color: 'link' }, "Saved addesses"),
                React.createElement(DropdownMenu, null, loading ?
                    React.createElement(AddressesLoadingItem, null) :
                    React.createElement(AddressesAsItems, { addresses: addresses, applyAddress: function (address) {
                            _this.toggle();
                            _this.props.applyAddress(address);
                        } })))));
    };
    return BootstrapSavedAddressesList;
}(React.Component));
export { BootstrapSavedAddressesList };
var UserOrCompanyEntitiesList = /** @class */ (function (_super) {
    __extends(UserOrCompanyEntitiesList, _super);
    function UserOrCompanyEntitiesList(props) {
        var _this = _super.call(this, props) || this;
        _this.gotEntities = function (entities) {
            _this.setState({
                entities: entities,
                loading: false
            });
        };
        _this.errorEntities = function () {
            _this.setState({
                entities: [],
                loading: false
            });
        };
        _this.fetchRelatedEntities = function () {
            var self = _this;
            var props = self.props;
            var entity = props.entity;
            var entitiesName = props.entitiesName;
            _this.setState({ loading: true }, function () {
                entity.get(function (responseUser) {
                    self.gotEntities(responseUser && responseUser[entitiesName]() ?
                        responseUser[entitiesName]() : null);
                }, self.errorEntities, { emailAddresses: {},
                    phoneNumbers: {} });
            });
        };
        _this.openDropdown = function (open) {
            _this.setState({ open: open }, function () {
                if (open) {
                    _this.fetchRelatedEntities();
                }
            });
        };
        _this.state = {
            entities: props.entities ? props.entities : [],
            loading: false,
            open: false,
        };
        return _this;
    }
    UserOrCompanyEntitiesList.prototype.render = function () {
        var _this = this;
        var entityDisplayName = this.props.entityDisplayName;
        var entityValue = this.props.entityValue;
        var loading = this.state.loading;
        var className = this.props.className ?
            this.props.className : '';
        var open = this.state.open ? ' open' : '';
        var entities = this.state.entities;
        var listItems = [];
        entities.map(function (entity) {
            listItems.push(React.createElement("li", { key: entity.id() },
                React.createElement("a", { onClick: function () {
                        _this.props.applyEntity(entity);
                        _this.openDropdown(false);
                    } }, entity[entityValue]())));
        });
        return (React.createElement("div", { className: className + open },
            React.createElement("button", { type: 'button', className: 'btn btn-link btn-xs dropdown-toggle p-0', onClick: function () {
                    _this.openDropdown(!_this.state.open);
                } },
                "Saved ",
                React.createElement("span", { className: 'hidden-md hidden-sm' }, entityDisplayName),
                " ",
                React.createElement("span", { className: 'caret' })),
            React.createElement("ul", { className: 'dropdown-menu', style: { textAlign: 'left', top: 'inherit' } }, loading ?
                (React.createElement("li", { className: 'text-center' },
                    htmlIcon(icons.loading),
                    " Loading...")) :
                entities.length > 0 ? listItems :
                    (React.createElement("li", { className: 'text-center' },
                        "No ",
                        entityDisplayName,
                        " found")))));
    };
    return UserOrCompanyEntitiesList;
}(React.Component));
export { UserOrCompanyEntitiesList };
export function SavedEmailAddressesList(props) {
    var entity = props.entity;
    return (React.createElement("div", null, entity ?
        React.createElement(UserOrCompanyEntitiesList, { className: props.className, applyEntity: props.applyEmail, entity: entity, entities: props.emails, entityDisplayName: 'emails', entitiesName: 'emailAddresses', entityValue: 'emailAddress' }) : null));
}
export function SavedPhoneNumbersList(props) {
    var entity = props.entity;
    return React.createElement(UserOrCompanyEntitiesList, { className: props.className, applyEntity: props.applyPhoneNumber, entity: entity, entities: props.phoneNumbers, entityDisplayName: 'phone numbers', entitiesName: 'phoneNumbers', entityValue: 'internationalFormatNumber' });
}
export function humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si ?
        ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
        ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}
export var uploadNewFiles = function (options) {
    var files = options.files;
    var filesArray = [];
    var inputFiles = Array.from(files);
    var allFiles = [];
    var fileUploaded = null;
    var i;
    var upload = function (index) {
        filesArray[index].onload = function (e) {
            fileUploaded = new MERCHI_SDK.File();
            fileUploaded.fromFormFile(inputFiles[index]);
            fileUploaded.publicCreate(function (response) {
                allFiles.push(response);
                if (options.success) {
                    options.success(response);
                }
                if (options.successAllFiles && files.length === allFiles.length) {
                    options.successAllFiles(allFiles);
                }
            }, options.error);
        };
        filesArray[index].readAsDataURL(inputFiles[index]);
    };
    for (i = 0; i < inputFiles.length; i++) {
        filesArray.push(new FileReader());
        upload(i);
    }
};
var ProfilePicuteDropZone = /** @class */ (function (_super) {
    __extends(ProfilePicuteDropZone, _super);
    function ProfilePicuteDropZone(props) {
        var _this = _super.call(this, props) || this;
        _this.setImageAndPreview = function (responseData) {
            _this.setState({ imgSrc: responseData.viewUrl() });
            if (_this.props.uploadSuccess) {
                _this.props.uploadSuccess(responseData);
            }
        };
        _this.error = function () {
            _this.setState({ imgSrc: null });
        };
        _this.onDrop = function (files) {
            _this.setState({
                imgSrc: merchiLoadingUrl
            }, function () {
                uploadNewFiles({
                    error: _this.error,
                    files: files,
                    success: _this.setImageAndPreview
                });
            });
        };
        _this.state = {
            imgSrc: props.defaultImageUrl,
        };
        return _this;
    }
    ProfilePicuteDropZone.prototype.componentWillReceiveProps = function (newProps) {
        var nextLogo = newProps.defaultImageUrl ?
            newProps.defaultImageUrl : null;
        var imgSrc = this.state.imgSrc;
        var noImage = !imgSrc || imgSrc === merchiLoadingUrl;
        if (noImage && nextLogo) {
            this.setState({ imgSrc: nextLogo });
        }
        if (!imgSrc && newProps.parentLoading && !nextLogo) {
            this.setState({ imgSrc: merchiLoadingUrl });
        }
        if (noImage && !newProps.parentLoading && !nextLogo) {
            this.setState({ imgSrc: null });
        }
    };
    ProfilePicuteDropZone.prototype.render = function () {
        var imgSrc = this.state.imgSrc;
        var _a = this.props, disabled = _a.disabled, iconUpload = _a.iconUpload, _b = _a.messageStyle, messageStyle = _b === void 0 ? { padding: '70px 26px' } : _b, multipleFile = _a.multipleFile, noBottomBorderRadius = _a.noBottomBorderRadius, uploadText = _a.uploadText;
        var maxHeight160 = { maxHeight: '160px', minHeight: '160px', cursor: 'pointer' };
        var imageClass = noBottomBorderRadius ? 'border-bottom-radius-0' : '';
        return (React.createElement("div", { className: 'profile-picture-upload' },
            React.createElement(Dropzone, { onDrop: this.onDrop, multiple: multipleFile, disabled: disabled }, function (_a) {
                var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
                return (React.createElement("div", { className: 'dropzone dropzone-single', style: maxHeight160 },
                    React.createElement("div", __assign({}, getRootProps(), { className: 'fallback', style: maxHeight160 }),
                        React.createElement("input", __assign({ type: 'file', style: maxHeight160, className: 'custom-file-input' }, getInputProps(), { accept: 'images/*' })),
                        imgSrc ?
                            React.createElement("div", { className: 'dz-preview dz-preview-single' },
                                React.createElement("div", { className: 'dz-preview-cover' },
                                    React.createElement("img", { className: 'dz-preview-img ' + imageClass, src: imgSrc, style: maxHeight160, "data-dz-thumbnail": true }))) :
                            React.createElement("div", { style: messageStyle, className: 'dz-message' },
                                iconUpload &&
                                    React.createElement("div", { className: 'd-block' },
                                        React.createElement(FontAwesomeIcon, { icon: iconUpload, size: '2x' })),
                                uploadText ? uploadText : 'Drop file here'))));
            })));
    };
    return ProfilePicuteDropZone;
}(React.Component));
export { ProfilePicuteDropZone };
export var ArgonSwitch = function (props) {
    var disabled = props.disabled, offText = props.offText, onText = props.onText;
    var spanAttributes = {
        'data-label-off': offText ? offText : '',
        'data-label-on': onText ? onText : '',
    };
    return (React.createElement("label", { className: 'custom-toggle' },
        React.createElement("input", { type: 'checkbox', readOnly: true, checked: props.isChecked, onClick: props.onClick }),
        !disabled ?
            React.createElement("span", __assign({ className: 'custom-toggle-slider rounded-circle' }, spanAttributes)) : null));
};
export var AddButton = function (props) {
    var _a = props.btnClass, btnClass = _a === void 0 ? 'btn btn-sm btn-primary' : _a;
    return (React.createElement("button", { className: btnClass, onClick: function (e) {
            e.preventDefault();
            props.onClick();
        }, "data-tip": true, "data-for": props.tooltipId }, iconElement(props.iconClass)));
};
export var AddButtonWithTooltip = function (props) {
    var tooltipId = MERCHI_SDK.generateUUID();
    var className = props.containerClass ? props.containerClass : '';
    return React.createElement("span", { className: className },
        React.createElement(TooltipElement, { text: props.tooltip, id: tooltipId }),
        React.createElement(Button, { color: 'primary', size: 'sm', className: 'pull-right', onClick: function (e) {
                e.preventDefault();
                props.onButtonClick();
            }, "data-tip": true, "data-for": tooltipId }, iconElement(icons.add)));
};
export var LinkWithIconAndToolTip = function (props) {
    var tooltipId = MERCHI_SDK.generateUUID();
    var containerClass = props.containerClass ? props.containerClass : '';
    var linkClass = props.linkClass ? props.linkClass : 'btn-sm btn-primary';
    var attributes = {};
    if (props.targetBlank) {
        attributes.target = '_blank';
    }
    return (React.createElement("span", { className: containerClass },
        React.createElement(TooltipElement, { text: props.tooltip, id: tooltipId }),
        React.createElement("a", __assign({ className: linkClass, href: props.linkUrl, "data-tip": true, "data-for": tooltipId }, attributes), iconElement(props.iconClass))));
};
export function AnalyticsCard(props) {
    var colourClass = props.colourClass, data = props.data, icon = props.icon, iconClass = props.iconClass, loading = props.loading, name = props.name;
    var colour = colourClass ? colourClass : 'info';
    var percent = data.percentageDifference ||
        data.percentageDifference === 0 ?
        parseInt(data.percentageDifference, 10) : null;
    var noChange = percent === 0;
    var isIncrease = !MERCHI_SDK.isUndefinedOrNull(data.percentageDifference) && data.percentageDifference >= 0;
    var iconContainerClass = "icon icon-shape bg-".concat(colour, " text-white rounded-circle");
    var increaseIcon = loading ? icons.loading : noChange ? '' :
        isIncrease ? 'fa fa-arrow-up' : 'fa fa-arrow-down';
    var increaseText = loading ? '' : noChange ? '' :
        isIncrease ? 'text-success' : 'text-danger';
    var total = data.totals;
    var displayTotal = MERCHI_SDK.isUndefinedOrNull(total) ? null :
        isNaN(total) ? total.currency + ' ' + formatCurrency(total.total, { currency: total.currency }) : total;
    return (React.createElement(Card, { className: 'card-stats' },
        React.createElement(CardBody, null,
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("h5", { className: 'card-title text-uppercase text-muted mb-0' }, name),
                    React.createElement("span", { className: 'h2 font-weight-bold mb-0' }, loading ? 'Loading...' : displayTotal)),
                React.createElement("div", { className: 'col-auto' },
                    React.createElement("div", { className: iconContainerClass }, icon ?
                        React.createElement(FontAwesomeIcon, { icon: loading ? faCircleNotch : icon, spin: loading })
                        : iconClass ?
                            iconElement(loading ? icons.loading : iconClass)
                            : null))),
            React.createElement("p", { className: 'mt-3 mb-0 text-sm' },
                React.createElement("span", { className: increaseText + ' mr-2' },
                    iconElement(increaseIcon),
                    " ",
                    MERCHI_SDK.isUndefinedOrNull(data.percentageDifference) ?
                        ' ... ' : (data.percentageDifference + '%')),
                React.createElement("span", { className: 'text-nowrap' }, "Last 30 days")))));
}
export function metricVolume(volume) {
    /* Take a volume in milimeters and converts it to m3 or cm3
         and return a string with the mm3, cm3 or m3 indicator
      */
    var millimeters = 1;
    var centermeters = 10 * millimeters;
    var centermeters3 = Math.pow(centermeters, 3);
    var meters = 1000 * millimeters;
    var meters3 = Math.pow(meters, 3);
    var m3;
    var cm3;
    if (volume) {
        if (volume > meters3) {
            m3 = volume / meters3;
            return String(m3) + ' m3';
        }
        if (volume > centermeters3) {
            cm3 = volume / centermeters3;
            return String(cm3) + ' cm3';
        }
        return String(volume) + ' mm3';
    }
    return 'Unknown';
}
export function metricWeight(weight) {
    /* Takes a weight in grams and returns a string with a 'grams',
         or 'kg' indicator depending on if the rams are greater than
         1 kiliogram
      */
    var grams = 1;
    var kilograms = 1000 * grams;
    var kg;
    if (weight) {
        if (weight > kilograms) {
            kg = weight / kilograms;
            return String(kg) + ' kg';
        }
        return String(weight) + ' grams';
    }
    return 'Unknown';
}
export var mounter = function (options) {
    var component = options.component, _a = options.id, id = _a === void 0 ? 'mountpoint-id' : _a;
    var element = document.getElementById(id);
    if (options.covertUserTs) {
        var merchiTs = new Merchi();
        options.currentUser = new merchiTs.User().fromJson(MERCHI_SDK.toJson(options.currentUser));
    }
    if (element) {
        ReactDOM.render(React.createElement(component, options), element);
    }
    else {
        console.warn("Couldn't find mount point element with id ".concat(id, " for component"), component);
    }
};
