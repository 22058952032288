import * as React from 'react';
import { generateUUID } from "merchi_sdk_ts";
import { TooltipElement } from "../../utilities";
export function Tooltip(props) {
    var children = props.children, tooltip = props.tooltip;
    var ttid = generateUUID();
    return tooltip ?
        React.createElement(React.Fragment, null,
            React.createElement(TooltipElement, { id: ttid, text: tooltip }),
            React.createElement("span", { "data-tip": true, "data-for": ttid }, children)) :
        React.createElement(React.Fragment, null, children);
}
