export var icons = {
    add: 'fa fa-plus',
    addSmall: 'icon_m_add_small',
    address: 'fas fa-map-marker-alt',
    address3x: 'fas fa-map-marker-alt fa-3x',
    address4x: 'fas fa-map-marker-alt fa-4x',
    admin: 'fas fa-users-cog',
    analytics: 'fas fa-chart-line',
    archive: 'fas fa-archive',
    arrowDown: 'fa fa-arrow-down',
    arrowLeft: 'fa fa-arrow-left',
    arrowRight: 'fa fa-arrow-right',
    arrowUp: 'fa fa-arrow-up',
    art: 'icon_m_paintbrush',
    assignmentDeadline: 'fas fa-stopwatch',
    attachment: 'icon_m_paperclip',
    ban: 'icon_m_ban',
    backup: 'fa fa-database',
    bank: 'fas fa-piggy-bank',
    bell: 'fas fa-bell',
    bellOld: 'far fa-bell',
    bitcoin: 'fab fa-bitcoin',
    calander: 'far fa-calendar-alt',
    calanderCompleted: 'far fa-calendar-check',
    calanderDispatch: 'far fa-calendar-alt',
    calanderDispatched: 'far fa-calendar-check',
    calanderExpectedReceive: 'far fa-calendar-alt',
    calanderReceived: 'far fa-calendar-check',
    calendar: 'far fa-calendar-alt',
    caretDown: 'fas fa-caret-down',
    categories: 'fas fa-tags',
    chartPie: 'fas fa-chart-pie',
    check: 'fas fa-check',
    checked: 'fas fa-check-square',
    checkedItem: 'fa fa-check-square',
    clock: 'fas fa-clock',
    clone: 'fa fa-clone',
    close: 'fa fa-times',
    closeSmall: 'fa fa-times',
    comment: 'fas fa-comment',
    comments: 'fas fa-comments',
    commentSend: 'icon_m_send_comment',
    companies: 'far fa-building',
    complete: 'fas fa-flag-checkered',
    completeUndo: 'fas fa-undo-alt',
    copyTemplate: 'icon_m_copy_to_template',
    copyText: 'icon_m_copy_text',
    currency: 'icon_m_currency',
    danger: 'fa fa-exclamation-circle',
    dashboard: 'fas fa-laptop',
    deadline: 'fas fa-stopwatch',
    design: 'fas fa-drafting-compass',
    draft: 'fas fa-drafting-compass',
    draft3x: 'fas fa-drafting-compass fa-3x',
    draftApproved: 'far fa-thumbs-up',
    draftApproved3x: 'far fa-thumbs-up fa-3x',
    draftChanges: 'far fa-thumbs-down',
    dropdown: 'fa fa-chevron-down',
    domain: 'fas fa-globe-asia',
    domainOrigin: 'icon_m_domain_origin',
    download: 'fas fa-download',
    edit: 'fa fa-edit',
    email: 'fas fa-envelope',
    emailClosed: 'fas fa-envelope',
    exclamationMark: 'fas fa-exclamation',
    exclamationMark2x: 'fas fa-exclamation fa-2x',
    exclamationMark3x: 'fas fa-exclamation fa-3x',
    exclamationMarkCircle: 'fas fa-exclamation-circle',
    exclamationMarkCircle2x: 'fas fa-exclamation-circle fa-2x',
    exclamationMarkCircle3x: 'fas fa-exclamation-circle fa-3x',
    eye: 'fa fa-eye',
    fileJpg: 'icon_m_file_photo',
    fileJpg3x: 'icon_m_file_photo icon_m_3x',
    fileJpg4x: 'icon_m_file_photo icon_m_4x',
    filePdf: 'far fa-file-pdf',
    filePdf3x: 'far fa-file-pdf icon_m_3x',
    filePdf4x: 'far fa-file-pdf icon_m_4x',
    files: 'icon_m_system_files',
    filter: 'fas fa-filter',
    flag: 'fas fa-flag-checkered',
    folderOpen: 'fa fa-folder-open',
    form: 'icon_m_form',
    globeOrigin: 'fas fa-globe-asia',
    handshake: 'icon_m_handshake',
    hashtag: 'fas fa-hashtag',
    hazard: 'icon_m_exclamation_mark',
    hazardTri: 'fas fa-exclamation-triangle',
    hourglass: 'far fa-hourglass',
    image: 'far fa-image',
    inbox: 'icon_m_inbox',
    info: 'fas fa-info-circle',
    info2x: 'icon_m_info icon_m_2x',
    inventory: 'fas fa-boxes',
    inventoryDeduct: 'far fa-calendar-minus',
    inventoryDeducted: 'far fa-calendar-check',
    inventoryMatched: 'far fa-check-square',
    inventoryUnmatched: 'far fa-square',
    invoice: 'fas fa-file-invoice-dollar',
    invoices: 'fas fa-file-invoice-dollar',
    job: 'fas fa-cube',
    jobs: 'fas fa-cubes',
    jobState: 'icon_m_complete',
    key: 'fas fa-key',
    loading: 'fa fa-circle-notch fa-spin',
    loading2x: 'fa fa-circle-notch fa-spin fa-2x',
    loading3x: 'fa fa-circle-notch fa-spin fa-3x',
    loading4x: 'fa fa-circle-notch fa-spin fa-4x',
    login: 'fas fa-sign-in-alt',
    logout: 'fas fa-sign-out-alt',
    pallet: 'fas fa-pallet',
    manufacturing: 'fas fa-hammer',
    manufacturing3x: 'fas fa-hammer fa-3x',
    mobilePhone: 'fas fa-mobile-alt',
    monster: 'icon_m_merchi_icon',
    monster2x: 'icon_m_merchi_icon icon_m_2x',
    monster3x: 'icon_m_merchi_icon icon_m_3x',
    navigation: 'icon_m_navigation',
    notificationChecked: 'far fa-check-square',
    notificationUnchecked: 'far fa-square',
    notify: 'fas fa-bullhorn',
    next: 'icon_m_next',
    notes: 'far fa-sticky-note',
    pageViews: 'fas fa-eye',
    paintbrush: 'icon_m_paintbrush',
    paperclip: 'fa fa-paperclip',
    payment: 'fas fa-credit-card',
    paypal: 'fa fa-paypal',
    paypalAlt: 'fab fa-cc-paypal',
    phonePayment: 'fas fa-mobile-alt',
    pointDown: 'icon_m_down',
    pointUp: 'icon_m_up',
    previous: 'icon_m_previous',
    procedure: 'icon_m_procedure',
    product: 'fas fa-tshirt',
    products: 'fas fa-tshirt',
    production: 'fas fa-hammer',
    productionQuote: 'fas fa-money-check-alt',
    private: 'fa fa-eye-slash',
    public: 'fa fa-eye',
    quote: 'fas fa-money-check-alt',
    quote3x: 'fas fa-money-check-alt fa-3x',
    quote4x: 'fas fa-money-check-alt fa-4x',
    refresh: 'fas fa-retweet',
    rejected: 'far fa-thumbs-down',
    reminder: 'fas fa-stopwatch',
    reopenJob: 'fas fa-exclamation-circle',
    responsbileShipper: 'fas fa-user-check',
    sales: 'fas fa-funnel-dollar',
    save: 'fas fa-save',
    search: 'fas fa-search',
    security: 'fas fa-shield-alt',
    settings: 'fas fa-cogs',
    shipmentReceiver: 'fas fa-map-marker-alt',
    shipmentScheduled: 'far fa-calendar-plus',
    shipmentScheduledNotFound: 'far fa-calendar-times',
    shipmentSender: 'fas fa-share-square',
    shipping: 'fas fa-truck',
    shipping2x: 'fas fa-truck fa-2x',
    shipping3x: 'fas fa-truck fa-3x',
    shoppingCart: 'fas fa-shopping-cart',
    shoppingCart2x: 'fas fa-shopping-cart fa-2x',
    store: 'fa fa-store',
    stripe: 'fab fa-cc-stripe',
    stripeAlt: 'fab fa-cc-stripe',
    success: 'fas fa-thumbs-up',
    success4x: 'fas fa-thumbs-up fa-3x',
    tableActions: 'fas fa-ellipsis-v',
    tag: 'fas fa-tag',
    tags: 'fas fa-tags',
    themes: 'far fa-window-restore',
    themeView: 'fa fa-eye',
    themeEdit: 'fa fa-edit',
    tidy: 'fas fa-code',
    tick: 'fas fa-check',
    timeLine: 'fas fa-tasks',
    times: 'fa fa-times',
    trackingNumber: 'fas fa-stream',
    trash: 'far fa-trash-alt',
    thumbsUp: 'far fa-thumbs-up',
    turnaroundTime: 'fas fa-stopwatch',
    turnaroundTime3x: 'fas fa-stopwatch fa-3x',
    warning: 'fa fa-exclamation-triangle',
    unarchive: 'fas fa-box-open',
    unCheckedItem: 'far fa-square',
    unpaid: 'fas fa-search-dollar',
    user: 'fa fa-user-circle',
    user3x: 'fa fa-user-circle fa-3x',
    user4x: 'fa fa-user-circle fa-4x',
    upload: 'fas fa-upload',
    users: 'fas fa-users',
    userEdit: 'fas fa-user-edit',
    userInfo: 'fas fa-address-card',
    userNew: 'fas fa-user-plus',
    userNew3x: 'fas fa-user-plus fa-3x',
    userRemove: 'fas fa-user-times',
    userResponsible: 'fas fa-user-tag',
    userResponsible2x: 'fas fa-user-tag fa-2x',
    userDisable: 'fas fa-user-slash',
    userDisable2x: 'fas fa-user-slash fa-2x',
    userReturning: 'fas fa-user-shield',
    userReturning3x: 'fas fa-user-shield fa-3x',
    view: 'far fa-eye',
    visibility: 'far fa-eye'
};
